import { ReactNode } from "react";
import useTimezone from "../../hooks/useTimezone";
import TableTextCell, { ITableTextCellProps } from "./TableTextCell";

export enum DateFormat {
    Date = "date",
    Time = "time",
    DateTime = "datetime",
    ShortDateTime = "shortdatetime",
    MonthYear = "monthyear",
}

interface ITableDateCellProps<D extends object = Record<string, unknown>> extends ITableTextCellProps<D> {
    format?: DateFormat;
    noTimezone?: boolean;
    nullValue?: ReactNode;
}

const TableDateCell = <D extends object = Record<string, unknown>>({
    value,
    format = DateFormat.Date,
    noTimezone = false,
    nullValue = null,
    ...textCellProps
}: ITableDateCellProps<D>) => {
    const { formatDate, formatMonthYear, formatTime, formatDateTime, formatShortDateTime } = useTimezone();
    const formatter = {
        [DateFormat.Date]: formatDate,
        [DateFormat.Time]: formatTime,
        [DateFormat.DateTime]: formatDateTime,
        [DateFormat.ShortDateTime]: formatShortDateTime,
        [DateFormat.MonthYear]: formatMonthYear,
    }[format];
    const formattedContent = value != null && formatter !== undefined ? formatter(value, { noTimezone }) : nullValue;
    return <TableTextCell value={formattedContent} {...textCellProps} />;
};

export default TableDateCell;
